<template>
  <VaLayout
      @left-overlay-click="isSidebarVisible = false"
  >
    <template #top>
      <VaNavbar color="#E8E8E8F2">
        <template #left>
          <h3 style="color: #42b983; font-size: 20px;">
            DIDATA
          </h3>
        </template>
        <template #right>
          <VaButton color="danger" @click.prevent="handleLogout">
            Sair
          </VaButton>
        </template>
      </VaNavbar>
      <VaDivider style="margin: 0" />
    </template>

    <template #left>
      <VaSidebar v-model="isSidebarVisible" style="min-height: 100vh" color="#E8E8E8FF">
        <VaSidebarItem
            v-for="{ icon, title, routeName, permission } in menu"
            :key="icon"
            :active="$route.name === routeName"
            active-color="secondary"
        >
          <router-link :to="{ name: routeName }" style="text-decoration: none; display: flex; color: #434343;">
            <VaSidebarItemContent v-if="typeUser === 'admin' || typeUser === permission">

                <VaIcon :name="icon" style="margin-right: 10px" />
                <VaSidebarItemTitle>
                  {{ title }}
                </VaSidebarItemTitle>
            </VaSidebarItemContent>
          </router-link>
        </VaSidebarItem>
      </VaSidebar>
    </template>

    <template #content>
      <main>
        <article>
          <slot />
        </article>
      </main>
    </template>
  </VaLayout>
</template>

<script>
export default {
  name: "LoggedLayout",

  data() {
    return {
      menu:  [
        { icon: 'home', title: 'Inicio', routeName: 'home', permission: 'normal' },
        { icon: 'group', title: 'Usuarios', routeName: 'users', permission: 'admin' },
        { icon: 'settings', title: 'Configurações', routeName: 'configs', permission: 'admin' },
        { icon: 'description', title: 'CRV-e', routeName: 'docs', permission: 'normal' },
        { icon: 'description', title: 'Reemissão CRLV-e RJ', routeName: 'crlvrj', permission: 'normal' },
        { icon: 'description', title: 'Nº ATPV-e', routeName: 'atpve', permission: 'normal' }
      ],
      isSidebarVisible: true
    }
  },

  computed: {
    typeUser () {
      const user = JSON.parse(localStorage.getItem('_user'));
      return user.type;
    }
  },

  methods: {
    handleLogout() {
      localStorage.clear();
      setTimeout(() => {
        window.location.href = '/login';
      }, 500);
    }
  },

  mounted() {
    console.log();
  }
}
</script>

<style scoped>

</style>