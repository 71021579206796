<template>
  <span v-if="isAuthenticated">
    <LoggedLayout>
    <template #default>
      <router-view />
    </template>
  </LoggedLayout>
  </span>
  <span v-else>
    <router-view />
  </span>
</template>

<script>
import LoggedLayout from "@/components/Layout/Logged.vue";

export default {
  name: 'App',
  components: {LoggedLayout},

  computed: {
    isAuthenticated () {
      return localStorage.getItem('_token');
    }
  }
}
</script>

<style>
</style>
