<template>
  <VaContent class="pa-5">
    <div class="row">
      <div class="flex flex-col md12">
        <div class="row">
          <div class="flex flex-col md4 px-4" v-if="typeUser === 'admin'">
            <VaCard>
              <VaCardTitle class="mb-0">
                <h5 class="va-text-capitalize mb-0">
                  Usuarios Cadastrados
                </h5>
              </VaCardTitle>
              <VaCardContent class="va-text-center">
                <div class="row mt-2 flex align-center">
                  <div class="flex flex-col md6 va-text-left">
                    <VaIcon name="group" size="md" />
                  </div>
                  <div class="flex flex-col md6 va-text-right">
                    <VaProgressCircle v-if="isLoadingUsers" indeterminate color="success" style="margin: 0 auto;" />
                    <span v-else class="va-h4">{{ counter_users }}</span>
                  </div>
                </div>
              </VaCardContent>
            </VaCard>
          </div>
          <div class="flex flex-col md4 px-4">
            <VaCard>
              <VaCardTitle class="mb-0">
                <h5 class="va-text-capitalize mb-0">
                  Documentos Gerados
                </h5>
              </VaCardTitle>
              <VaCardContent class="va-text-center">
                <div class="row mt-2 flex align-center">
                  <div class="flex flex-col md6 va-text-left">
                    <VaIcon name="description" size="md" />
                  </div>
                  <div class="flex flex-col md6 va-text-right">
                    <VaProgressCircle v-if="isLoadingDocs" indeterminate color="success" style="margin: 0 auto;" />
                    <span v-else class="va-h4">{{ docsCounter }}</span>
                  </div>
                </div>
              </VaCardContent>
            </VaCard>
          </div>
          <div class="flex flex-col md4 px-4">
            <VaCard>
              <VaCardTitle class="mb-0">
                <h5 class="va-text-capitalize mb-0" v-if="typeUser === 'admin'">
                  Saldo Geral
                </h5>
                <h5 class="va-text-capitalize mb-0" v-else>
                  Saldo
                </h5>
              </VaCardTitle>
              <VaCardContent class="va-text-center">
                <div class="row mt-2 flex align-center">
                  <div class="flex flex-col md6 va-text-left">
                    <VaIcon name="monetization_on" size="md" />
                  </div>
                  <div class="flex flex-col md6 va-text-right">
                    <VaProgressCircle v-if="isLoadingBalance" indeterminate color="success" style="margin: 0 auto;" />
                    <span v-else class="va-h4">
                      {{ balance }}
                    </span>
                  </div>
                </div>
              </VaCardContent>
            </VaCard>
          </div>
        </div>

        <VaCard class="mt-5 mx-4">
          <VaCardTitle>
            <h5 class="va-text-capitalize mb-0">
              Últimos Documentos Gerados
            </h5>
          </VaCardTitle>
          <VaCardContent>
            <div class="va-table-responsive">
              <VaProgressCircle v-if="isLoadingDocs" indeterminate color="success" style="margin: 0 auto;" />
              <VaDataTable
                  :items="items"
                  :columns="columns"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :filter="filter"
                  @filtered="filtered = $event.items"
                  v-else
              >
                <template #bodyAppend>
                  <tr>
                    <td colspan="6">
                      <div class="flex justify-center mt-4">
                        <VaPagination
                            v-model="currentPage"
                            :pages="pages"
                        />
                      </div>
                    </td>
                  </tr>
                </template>
                <template #cell(local)="{ rowData, value }">
                  <a :href="handleDownloadPDF(rowData)" download target="_blank">
                    {{ value }}
                    <VaIcon name="open_in_new" size="20px" class="ml-2" />
                  </a>
                </template>
              </VaDataTable>
            </div>
          </VaCardContent>
        </VaCard>
      </div>
    </div>
  </VaContent>
</template>

<script>
import request from "@/service/request";
import { BASE_API_URL } from "@/env";

export default {
  name: 'HomeView',

  data() {
    return {
      docs: [],
      docsCounter: 0,
      columns: [
        { key: "_id", sortable: true, width: "180px" },
        { key: "plate", sortable: true, width: "80px" },
        { key: "local", width: "180px" },
        { key: "user", sortable: true, width: "100px" },
        { key: "created_at", width: "120px" },
      ],
      perPage: 10,
      currentPage: 1,
      filter: "",
      balance: 0,
      counter_users: 0,
      isLoadingUsers: 0,
      isLoadingDocs: 0,
      isLoadingBalance: 0,
    }
  },

  components: {
    //
  },

  computed: {
    items () {
      return this.docs;
    },
    filtered() {
      return this.docs;
    },
    pages() {
      return this.perPage && this.perPage !== 0
          ? Math.ceil(this.filtered.length / this.perPage)
          : this.filtered.length;
    },
    baseAPIUrl() {
      return BASE_API_URL;
    },
    typeUser () {
      const user = JSON.parse(localStorage.getItem('_user'));
      return user.type;
    },
  },

  methods: {
    fetchDocs() {
      this.isLoadingDocs++;
      setTimeout(() => {
        request.get('api/v1/doc')
            .then((response) => {
              this.docsCounter = response.data.data.length;
              this.docs = response.data.data.slice(0, 5);
              this.isLoadingDocs--;
            })
            .catch((error) => {
              this.$vaToast.init({
                message: 'Erro ao buscar Documentos',
                color: 'danger'
              });
              console.log(error);
              this.isLoadingDocs--;
            });
      }, 1000)
    },
    fetchCountUser() {
      this.isLoadingUsers++;
      request.get('api/v1/count_user')
          .then((response) => {
            this.counter_users = response.data.data;
            this.isLoadingUsers--;
          })
          .catch((error) => {
            this.$vaToast.init({
              message: 'Erro ao buscar Documentos',
              color: 'danger'
            });
            console.log(error);
            this.isLoadingUsers--;
          });
    },
    getBalance() {
      this.isLoadingBalance++;
      request.get('api/v1/get_balance')
          .then((response) => {
            this.balance = response.data.data.credits;
            this.isLoadingBalance--;
          })
          .catch((error) => {
            console.log('error', error.response);
            this.isLoadingBalance--;
          });
    },
    handleDownloadPDF(row) {
      const token = localStorage.getItem('_token');
      return `${this.baseAPIUrl}/api/v1/download_pdf/${row._id}?t=${token}`;
    }
  },

  mounted() {
    if (this.typeUser === 'admin') {
      this.fetchCountUser();
    }

    this.getBalance();
    this.fetchDocs();
  }
}
</script>

<style scoped>
</style>