import axios from 'axios';
import { BASE_API_URL } from '@/env';

const request = axios.create({
    baseURL: BASE_API_URL,
    timeout: 0
});

request.interceptors.request.use((config) => {
    const token = localStorage.getItem('_token')
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    return config
});

request.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response) {
            if (error.response.status === 401) {
                alert('Sessão Expirada')

                setTimeout(() => {
                    localStorage.clear();
                    window.location.href = '/login';
                }, 500)
            }
        }
        return Promise.reject(error)
    },
);

export default request;
